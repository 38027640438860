@import 'assets/styles/variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';


// $primary : #169B88;
// $secondary : #626262;
// $secondary-light : #EAEAEA;
// $gray : #EAEAEA;
// $white : #FFFFFF;
// $black : #000000;
// $danger : #FF0000;
// $warning : #FF9407;
// $success : #169B88;
// $info : #169B88;
// $light : #EAEAEA;
// $dark : #626262;
// $font-family : 'Roboto', sans-serif;

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    // padding: 50px 0 0 0;
    // overflow-x: hidden;
    font-family: $font-family !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    background-color: $body-bg;
    ::-webkit-scrollbar {
        width: 2px;
    }
    ::-webkit-scrollbar-track {
        background: $white;
    }
    ::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: $primary;
    }
}
section{
  animation: slide-up 0.5s ease-in-out !important;
  position: relative;
  z-index: 1;
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
}
.mdc-dialog__container{
  .mat-mdc-dialog-surface{
    border-radius: 20px;
  }
}
