@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;400;500;600;800&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

$body-bg: #E2E2E2;
$primary : #169B88;
$secondary : #626262;
$gray : #EAEAEA;
$white : #FFFFFF;
$light : #EAEAEA;
$dark : #000000;
$blue : #1E85FF;
$font-family : 'Noto Sans Arabic','Rubik', sans-serif;

// bootstrap variables
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "white" : $white,
  "dark": $dark,
  "blue": $blue,
  // "success": $success,
  // "info": $info,
  // "warning": $warning,
  // "danger": $danger,
  "light": $light,
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

.btn{
  border-radius: 23px !important;
  padding: 10px 23px !important;
  // ripple effect
  background-position: center;
  transition: background 0.8s;

  &.btn-primary {
    color: #fff !important;
    &:hover {
      background: $primary radial-gradient(circle, transparent 1%, $primary 1%) center/15000%;
    }
    &:active {
      background: $white radial-gradient(circle, transparent 1%, $white 1%) center/15000%;
      color: $primary !important;
      background-size: 100%;
      transition: background 0s;
    }
  }
  &.btn-blue {
    color: #fff !important;
    &:hover {
      background: $blue radial-gradient(circle, transparent 1%, $blue 1%) center/15000%;
    }
    &:active {
      background: $white radial-gradient(circle, transparent 1%, $white 1%) center/15000%;
      color: $blue !important;
      background-size: 100%;
      transition: background 0s;
    }
  }
}
